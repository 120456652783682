import { findInAttributeData, isZero } from './cellValue';
import {
	ETimePeriodSuffixes,
	ICapitalActivityData,
	IRow,
	TemplateHelper,
} from './types';

export class YTDTemplateHelper implements TemplateHelper {
	shouldRenderHiddableRow(row: IRow, CASData: ICapitalActivityData[]): boolean {
		const cellValue = findInAttributeData(
			row.label.text,
			CASData,
			ETimePeriodSuffixes.YTD,
		);

		if (!isZero(cellValue?.value)) return true;

		return false;
	}
}
