import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import { LoginProps } from './types';
import { LoginBox, LoginNotMobile, SponsorLogo } from './styles';
import { clearUrlParams } from './utils';
import { defaultColor } from 'api/redux/siteColorReducer';

export const Login: FC<LoginProps> = (props) => {
	const { loginWithRedirect } = useAuth0();
	const [, setCookie] = useCookies(['subdomain', 'redirectUrl']);

	const LoginButton = styled(Button)(() => ({
		backgroundColor: props.color || defaultColor,
		color: '#FFFFFF',
		textTransform: 'none',
		fontSize: '1.0rem',
		width: 135,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: `${props.color || defaultColor} !important`,
			boxShadow: 'none',
		},
		'&:focus': {
			backgroundColor: `${props.color || defaultColor} !important`,
		},
	}));

	useEffect(() => {
		if (props.subdomain) {
			setCookie('subdomain', props.subdomain, { path: '/' });
		}

		if (props.redirectUrl) {
			setCookie('redirectUrl', props.redirectUrl, { path: '/' });
		}
	}, [props.subdomain, setCookie]);

	const handleLogin = () => {
		if (props.redirectUrl) {
			sessionStorage.setItem(
				STORAGE_KEYS.REDIRECT_URL.COOKIE,
				props.redirectUrl,
			);
		}
		loginWithRedirect();
	};

	useEffect(() => {
		history.replaceState(null, '', clearUrlParams());
	}, []);

	return (
		<LoginBox>
			<LoginNotMobile>
				{props.logo && <SponsorLogo src={props.logo} />}
				<LoginButton
					variant="contained"
					size="large"
					disableRipple
					onClick={handleLogin}
				>
					Log In
				</LoginButton>
			</LoginNotMobile>
		</LoginBox>
	);
};
