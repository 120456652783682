import { initializeApp } from 'firebase/app';
import {
	getRemoteConfig as getFirebaseRemoteConfig,
	getValue,
	fetchAndActivate,
} from 'firebase/remote-config';
import firebaseConfig from './firebaseConfig.json';

export enum RemoteConfigKeys {
	MAINTENANCE_MODE = 'maintenance_mode',
}

const app = initializeApp(firebaseConfig);
const remoteConfig = getFirebaseRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000; // 1 minute
remoteConfig.defaultConfig = {
	maintenance_mode_local: false,
	maintenance_mode_dev: false,
	maintenance_mode_qa: false,
	maintenance_mode_stage: false,
	maintenance_mode_prod: false,
};

export const getRemoteConfigFlag = async (
	key: RemoteConfigKeys,
	environment: string,
): Promise<boolean> => {
	await fetchAndActivate(remoteConfig);
	const environmentKey = `${key}_${environment}`;
	return getValue(remoteConfig, environmentKey).asBoolean();
};
