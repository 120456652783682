import { FC } from 'react';
import { Box } from '@mui/material';
import { RowLabel, TableCell, TableRow } from './styles';
import { ICASTableRowProps, RowLabels, IColumn } from './types';
import { MTDCASTableCell } from './MTDCASTableCell';
import { cellLabelStyle } from './styles';

export const MTDCASTableRow: FC<ICASTableRowProps> = ({
	row,
	columns,
	CASData,
	templateType,
}) => {
	return (
		<TableRow
			className={
				row.valueFormats.length ? 'cas-row content-row' : 'cas-row blank-row'
			}
		>
			<TableCell className="cas-cell">
				<Box>
					<RowLabel style={cellLabelStyle(row)}>
						{RowLabels[row.label.text]}
					</RowLabel>
				</Box>
			</TableCell>
			{columns.map((column: IColumn, j) => (
				<MTDCASTableCell
					key={j}
					row={row}
					column={column}
					CASData={CASData}
					templateType={templateType}
				/>
			))}
		</TableRow>
	);
};
