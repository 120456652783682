import { ISponsor } from 'app/types/sponsor.types';
import { URLS_TO_SAVE } from './types';
import { defaultColor } from 'api/redux/siteColorReducer';
import { useMemo } from 'react';

export const buildBrandedUrl = (
	org: ISponsor | undefined,
	subdomain: string,
	darkLogoUrl: string,
	baseUrl: string,
): string => {
	const url = new URL(baseUrl);

	const redirectUrl = getRedirectUrl();
	if (redirectUrl) {
		url.searchParams.append('redirectUrl', redirectUrl);
	}

	if (!org) return url.toString();

	url.searchParams.append('orgName', org.name);
	url.searchParams.append('logo', darkLogoUrl || '');
	url.searchParams.append('auth0OrgId', org.auth0Id || '');
	url.searchParams.append('orgId', org.id.toString() || '');
	url.searchParams.append('sub', subdomain);
	url.searchParams.append('color', org.headerColor || defaultColor);

	return url.toString();
};

export const clearUrlParams = (): string => {
	const url = new URL(window.location.href);
	const paramsToRemove = [
		'orgName',
		'logo',
		'auth0OrgId',
		'orgId',
		'sub',
		'subdomain',
		'redirectUrl',
		'color',
	];

	paramsToRemove.forEach((param) => url.searchParams.delete(param));
	return url.toString();
};

export const useUrlParams = () => {
	return useMemo(() => {
		const searchParams = new URLSearchParams(window.location.search);
		return {
			orgName: searchParams.get('orgName') || undefined,
			logo: searchParams.get('logo') || '/lpx_logo.jpg',
			subdomainParam: searchParams.get('sub') || '',
			redirectUrl: searchParams.get('redirectUrl') || undefined,
			color: searchParams.get('color') || defaultColor,
			subdomain:
				searchParams.get('subdomain') || window.location.hostname.split('.')[0],
		};
	}, []);
};

export const getRedirectUrl = (): string => {
	const { pathname, search } = window.location;
	return URLS_TO_SAVE.some((url) => pathname.includes(url))
		? `${pathname}${search}`
		: '';
};
