import { FC, useEffect, useState } from 'react';
import CapitalAccountStatement from '../CapitalAccountStatement';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { useGetReportTypesQuery } from 'api/redux/services/reportTypes.service';
import { ReportType } from 'app/types/reportTypes.types';
import { useLazyGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';

export enum ReportTypeNames {
	CAPITAL_ACCOUNT_STATEMENT = 'Capital Account Statement',
}

export const Reports: FC = () => {
	const { isAdmin } = useGrants();
	const grants = useSelector(selectDropdown);
	const { currentInvestor } = grants.grants;
	const [selectedReportType, setSelectedReportType] = useState<string>('');
	const { data: availableReportTypes } = useGetReportTypesQuery();
	/** The following line is reserving space for other report types when we make use of them */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [reportTypes, setReportTypes] = useState<ReportType[]>([]);
	const [fetchInvestorPermissions, userInvestorPermissions] =
		useLazyGetInvestorPermissionsQuery();

	function Chart(reportTypeName) {
		switch (reportTypeName) {
			case ReportTypeNames.CAPITAL_ACCOUNT_STATEMENT:
				return <CapitalAccountStatement />;
			default:
				return;
		}
	}

	useEffect(() => {
		if (!isAdmin && currentInvestor.id !== 0)
			fetchInvestorPermissions({ investorId: currentInvestor.id });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor.id]);

	useEffect(() => {
		if (!isAdmin && availableReportTypes && userInvestorPermissions.data) {
			const [firstReportType] = availableReportTypes;

			const allowedReportTypeIds: number[] =
				userInvestorPermissions?.data?.reportTypes?.map((rt) => rt.id);
			const reportTypesToShow: ReportType[] = availableReportTypes.filter(
				(reportType: ReportType) =>
					allowedReportTypeIds?.includes(reportType.id),
			);

			setReportTypes(reportTypesToShow);
			setSelectedReportType(firstReportType.name);
		}
	}, [availableReportTypes, isAdmin, userInvestorPermissions.data]);

	useEffect(() => {
		if (isAdmin && availableReportTypes) {
			const [firstReportType] = availableReportTypes;
			setReportTypes(availableReportTypes);
			setSelectedReportType(firstReportType.name);
		}
	}, [availableReportTypes, isAdmin]);

	return (
		<MainSidebarContainer>{Chart(selectedReportType)}</MainSidebarContainer>
	);
};

export default Reports;
