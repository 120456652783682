import { Table as MuiTable, Paper, Typography } from '@mui/material';
import { TableCell as MuiTableCell } from '@mui/material';
import { TableRow as MuiTableRow } from '@mui/material';
import { ETimePeriodSuffixes, IColumn, ILabel, IRow } from '../types';
import { CSSProperties } from 'react';
import {
	CapitalCommitmentTableLabels,
	CellValueFormats,
	RowNames,
	TemplateType,
} from './types';
import { styled } from '@mui/system';

export const ColumnSubLabel = styled(Typography)(() => ({
	fontSize: '14px',
	textAlign: 'center',
}));

export const TablePaper = styled(Paper)(() => ({
	paddingBottom: 10,
	border: '1px solid #D0D7DE',
	borderRadius: '5px 5px 0px 0px',
	overflow: 'hidden',
}));

export const ColumnLabels = styled(Typography)(() => ({
	fontSize: '14px',
	color: '#FFFFFF',
	textAlign: 'center',
}));

export const RowLabel = styled(Typography)(() => ({
	fontSize: '14px',
}));

export const Table = styled(MuiTable)({
	root: {
		borderCollapse: 'separate',
		'& tr.content-row:hover': {
			backgroundColor: '#eee',
		},
		'& .MuiTableCell-sizeSmall': {
			padding: '0px 10px 0px 10px',
		},
	},
});

export const TableCell = styled(MuiTableCell)({
	root: {
		borderBottom: 'none !important',
		borderRadius: '0px',
	},
});

export const TableRow = styled(MuiTableRow)({
	root: {
		borderBottom: 'none',
	},
});

export const cellLabelStyle = (row: IRow): CSSProperties => {
	return {
		whiteSpace: 'nowrap',
		paddingLeft: row.label.formats.includes('INDENT') ? 10 : 0,
	};
};

export const cellFontWeight = (label: ILabel): string =>
	label.formats.includes('BOLD') ? 'bold' : 'normal';

export const underlineCell = (
	row: IRow,
	column: IColumn,
	color: string,
	templateType: TemplateType,
): string => {
	if (
		CapitalCommitmentTableLabels.includes(row.label.text as RowNames) &&
		column.timePeriodSuffix === ETimePeriodSuffixes.ITD &&
		templateType === TemplateType.MTD
	)
		return '';

	if (row.valueFormats.includes(CellValueFormats.DOUBLE_UNDERLINE)) {
		return `double 3.75px ${color}`;
	}

	return '0';
};

export const topLineCell = (
	row: IRow,
	column: IColumn,
	color: string,
	templateType: TemplateType,
): string => {
	if (
		CapitalCommitmentTableLabels.includes(row.label.text as RowNames) &&
		column.timePeriodSuffix === ETimePeriodSuffixes.ITD &&
		templateType === TemplateType.MTD
	)
		return '';

	if (row.valueFormats.includes(CellValueFormats.TOP_LINE)) {
		return `solid 1.25px ${color}`;
	}

	return '0';
};
