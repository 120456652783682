import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query';
import { RootState } from '../api/redux/store';
import { configApi } from '../api/redux/services/config';
import {
	QueryArgs,
	buildAdjustedUrl,
	createAdjustedArgs,
	handleUnauthorizedError,
	rawBaseQuery,
} from './dynamic-base-query-helpers';

const dynamicBaseQuery =
	(stem: string): BaseQueryFn<QueryArgs, unknown, FetchBaseQueryError> =>
	async (args: QueryArgs, api: BaseQueryApi, extraOptions: {}) => {
		const state = api.getState() as RootState;
		const { data: config } = configApi.endpoints.getConfig.select()(state);

		const urlEnd = typeof args === 'string' ? args : args.url;
		const adjustedUrl = buildAdjustedUrl({
			baseUrl: config?.apiBaseUrl,
			stem,
			urlEnd,
		});
		const adjustedArgs = createAdjustedArgs(args, adjustedUrl);

		const result = await rawBaseQuery(adjustedArgs, api, extraOptions);

		if (result.error?.status === 401) {
			handleUnauthorizedError(result.error.data);
		}

		return result;
	};

export default dynamicBaseQuery;
