import { FC } from 'react';
import { ICapitalAccountStatementTableProps } from './types';
import { Alert } from '@mui/material';
import { TablePaper } from './styles';
import { YTDCASTableHeaders } from './YTDCASTableHeaders';
import { YTDCASTableBody } from './YTDCASTableBody';
import { Table } from './styles';
import { TableContainer } from '@mui/material';

export const YTDCASTable: FC<ICapitalAccountStatementTableProps> = ({
	data,
}) => {
	const { capitalActivityTemplate, attributeData, reportingPeriod } =
		data || {};

	if (!capitalActivityTemplate)
		return (
			<Alert severity="error" variant="filled">
				Error loading YTD Capital Account Statement template.
			</Alert>
		);

	if (!attributeData)
		return (
			<Alert severity="error" variant="filled">
				Error loading your Capital Account Statement.
			</Alert>
		);

	return (
		<TablePaper elevation={0}>
			<TableContainer style={{ height: '100%', overflowX: 'visible' }}>
				<Table size="small" style={{ height: '100%' }}>
					<YTDCASTableHeaders
						capitalActivityTemplate={capitalActivityTemplate}
						reportingPeriod={reportingPeriod}
					/>
					<YTDCASTableBody
						capitalActivityTemplate={capitalActivityTemplate}
						CASData={attributeData}
					/>
				</Table>
			</TableContainer>
		</TablePaper>
	);
};
