import { selectDropdown } from 'api/redux/DropdownReducer';
import { FC, ReactNode, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { STORAGE_KEYS } from './dropdowns/Helpers/DropdownReducerConstants';
import { ERoutes } from 'App';
import { useLocation } from 'react-router-dom';

interface CookiesManagerProps {
	children: ReactNode;
}

export const CookiesManager: FC<CookiesManagerProps> = ({ children }) => {
	const { pathname } = useLocation();
	const [, setCookie] = useCookies([
		STORAGE_KEYS.FUND.COOKIE,
		STORAGE_KEYS.SPONSOR.COOKIE,
		STORAGE_KEYS.INVESTOR.COOKIE,
		STORAGE_KEYS.DROPDOWN.COOKIE,
	]);
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor, currentFund, currentInvestor, firstDropdown } =
		grants;
	const isAdminView = pathname.includes('admin');

	const saveStateInCookies = () => {
		setCookie(STORAGE_KEYS.SPONSOR.COOKIE, currentSponsor.id, {
			path: ERoutes.Dashboard,
		});
		setCookie(STORAGE_KEYS.FUND.COOKIE, currentFund.id, {
			path: ERoutes.Dashboard,
		});
		setCookie(STORAGE_KEYS.INVESTOR.COOKIE, currentInvestor.id, {
			path: ERoutes.Dashboard,
		});
		setCookie(STORAGE_KEYS.DROPDOWN.COOKIE, firstDropdown, {
			path: ERoutes.Dashboard,
		});
	};

	useEffect(() => {
		if (currentSponsor.id !== 0 && !isAdminView) {
			saveStateInCookies();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSponsor]);

	useEffect(() => {
		if (currentFund.id !== 0 && !isAdminView) {
			saveStateInCookies();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFund]);

	useEffect(() => {
		if (currentInvestor.id !== 0 && !isAdminView) {
			saveStateInCookies();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor]);

	useEffect(() => {
		if (currentSponsor.id !== 0 && !isAdminView) {
			saveStateInCookies();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstDropdown]);

	return <>{children}</>;
};
