import { FC, useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { EditButton } from 'common/components/EditButton';
import { DeleteButton } from 'common/components/DeleteButton';
import PermissionsWrapper, {
	hasPermission,
} from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { DeleteInvestorModal } from './dialogs/DeleteInvestorModal';
import { Investor } from 'app/types/ui/investor.types';
import MUIDataTable from 'mui-datatables';
import { useRemoveInvestorFromFundMutation } from 'api/redux/services/fundApi';
import { EditInvestorModal } from 'modules/investor-view/investor-info/EditInvestorModal';
import { BulkUploadInvestors } from './dialogs/bulk-upload/BulkUploadInvestors';
import { BulkUploadInvestorsTemplate } from './dialogs/bulk-upload/BulkUploadInvestorsTemplate';
import { IconBtn } from 'common/components/IconBtn';
import { GroupAdd, PersonAddAlt1 } from '@mui/icons-material';
import { useGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';
import { useGetCurrentUserGrantsQuery } from 'api/redux/services/userApi';
import { styled } from '@mui/system';

const PaperBox = styled(Paper)(() => ({
	width: '100%',
	marginTop: 16,
	borderRadius: 10,
	padding: 16,
}));

export const ManageInvestors: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const [editOpen, setEditOpen] = useState(false);
	const [removeInvestorFromFund, deleteData] =
		useRemoveInvestorFromFundMutation();
	const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
	const [selectedInvestor, setSelectedInvestor] = useState<Investor>({
		id: 0,
		name: '',
	});
	const { data: investors = [], refetch } = useGetInvestorsQuery(
		currentFund.id,
	);
	const { refetch: refetchGrants } = useGetCurrentUserGrantsQuery();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [bulkDialogOpen, setBulkDialogOpen] = useState(false);

	useEffect(() => {
		refetch();
	}, [deleteData, editOpen, refetch, dialogOpen]);

	useEffect(() => {
		if (selectedInvestor.id && !deleteOpen) {
			setEditOpen(true);
		}
	}, [selectedInvestor]);

	const columns = [
		{ name: 'id', options: { display: false, filter: false } },
		{
			name: 'name',
			label: 'Investor Name',
			options: { setCellProps: () => ({ style: { minWidth: '40%' } }) },
		},
		{
			name: 'investorId',
			label: 'Unique Investor ID',
			options: { setCellProps: () => ({ style: { minWidth: '40%' } }) },
		},
		{
			name: 'actions',
			options: {
				customBodyRender: (_: any, tableMeta: any) => (
					<div style={{ float: 'right' }}>
						<PermissionsWrapper scopes={[SCOPES.canEditInvestor]}>
							<EditButton onClick={(e) => onEdit(tableMeta, e)} />
						</PermissionsWrapper>
						<PermissionsWrapper scopes={[SCOPES.canDeleteInvestor]}>
							<DeleteButton onClick={(e) => onDelete(tableMeta, e)} />
						</PermissionsWrapper>
					</div>
				),
				customHeadRender: () => null,
			},
		},
	];

	const onEdit = (value, e) => {
		e.stopPropagation();
		setSelectedInvestor({
			id: value.rowData[0],
			name: value.rowData[1],
		});
	};

	const onDelete = (value, e) => {
		e.stopPropagation();
		setSelectedInvestor({
			id: value.rowData[0],
			name: value.rowData[1],
		});
		setDeleteOpen(true);
	};

	const handleDeleteConfirm = async () => {
		await removeInvestorFromFund({
			investorId: selectedInvestor.id,
			fundId: currentFund.id,
		});

		setDeleteOpen(false);
	};

	const handleDeleteClose = () => {
		setDeleteOpen(false);
	};

	const closeEditModal = () => {
		setEditOpen(false);
		setDialogOpen(false);
	};

	const handleAfterSave = () => {
		closeEditModal();
		setBulkDialogOpen(false);
		refetchGrants();
	};

	const options = {
		rowHover: false,
		elevation: 0,
		filter: false,
		pagination: false,
		print: false,
		selectableRows: 'none' as const,
		viewColumns: false,
		download: hasPermission({ scopes: [SCOPES.canDownloadInvestor] }),
		search: hasPermission({ scopes: [SCOPES.canSearchInvestor] }),
	};

	return (
		<PaperBox>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					paddingRight: '30px',
				}}
			>
				<Typography variant="h5">Investors</Typography>
				<PermissionsWrapper scopes={[SCOPES.canCreateInvestor]}>
					<Box>
						<IconBtn onClick={() => setDialogOpen(true)} tooltip="Add Investor">
							<PersonAddAlt1 />
						</IconBtn>
						<BulkUploadInvestorsTemplate />
						<IconBtn
							onClick={() => setBulkDialogOpen(true)}
							tooltip="Bulk Upload Investors"
						>
							<GroupAdd />
						</IconBtn>
					</Box>
				</PermissionsWrapper>
			</Box>
			<MUIDataTable
				title=""
				columns={columns}
				data={investors}
				options={options}
			/>{' '}
			{dialogOpen && (
				<EditInvestorModal
					investor={undefined}
					isOpen={dialogOpen}
					onClose={() => closeEditModal()}
					afterSave={() => handleAfterSave()}
					editContactInfo={true}
				/>
			)}
			{editOpen && (
				<EditInvestorModal
					investor={investors.find(
						(investor) => investor.id === selectedInvestor.id,
					)}
					isOpen={editOpen}
					onClose={() => closeEditModal()}
					afterSave={() => handleAfterSave()}
					editContactInfo={false}
				/>
			)}
			{deleteOpen && (
				<DeleteInvestorModal
					open={deleteOpen}
					investor={selectedInvestor}
					handleSubmit={handleDeleteConfirm}
					handleClose={handleDeleteClose}
				/>
			)}
			{bulkDialogOpen && (
				<BulkUploadInvestors
					open={bulkDialogOpen}
					handleClose={() => handleAfterSave()}
				/>
			)}
		</PaperBox>
	);
};
