import { Box, Menu, MenuItem, styled } from '@mui/material';
import { onChangeSponsor, selectDropdown } from 'api/redux/DropdownReducer';
import { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILogo } from 'api/redux/services/organizations.service';
import { MenuIcon } from './icons/SponsorDropdownIcons';

const LinkBox = styled(Box)(() => ({
	color: '#F1F2F9',
	margin: '0 1em',
	fontSize: '16px',
	minWidth: '50px',
	display: 'flex',
	cursor: 'pointer',
}));

const ImgBox = styled('img')(() => ({
	maxWidth: '300px',
	maxHeight: '45px',
	minWidth: '50px',
}));

export const LogoSponsorDropdown: FC<ILogo> = ({ url }) => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor, availableSponsors } = grants.grants;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const dispatch = useDispatch();
	const imgRef = useRef<HTMLDivElement>(null);

	const handleMenuOpen = () => {
		if (availableSponsors && availableSponsors.length > 1) {
			setAnchorEl(imgRef.current);
		}
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleSponsorChange = (org: any) => {
		dispatch(onChangeSponsor(org));
		handleMenuClose();
	};

	return (
		<>
			<LinkBox
				ref={imgRef}
				onClick={handleMenuOpen}
				role="button"
				tabIndex={0}
				aria-controls={isMenuOpen ? 'sponsor-menu' : undefined}
				aria-expanded={isMenuOpen}
				aria-haspopup="menu"
			>
				<ImgBox src={url} alt={currentSponsor.name} aria-hidden="true" />
				{availableSponsors && availableSponsors.length > 1 ? (
					<MenuIcon isMenuOpen={isMenuOpen} />
				) : null}
			</LinkBox>
			<Menu
				id="sponsor-menu"
				anchorEl={anchorEl}
				open={isMenuOpen}
				onClose={handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'sponsor-button',
					role: 'menu',
				}}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				slotProps={{
					paper: {
						elevation: 8,
						sx: { minWidth: '200px' },
					},
				}}
			>
				{availableSponsors.map((org) => (
					<MenuItem
						key={org.id}
						onClick={() => handleSponsorChange(org)}
						role="menuitem"
					>
						{org.name}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
