import { ITDTemplateHelper } from './ITDHelper';
import {
	EHiddenBehavior,
	ICapitalActivityData,
	IRow,
	TemplateHelper,
	TemplateType,
} from './types';

const helperClass = (templateType: TemplateType): TemplateHelper => {
	const map = {
		[TemplateType.ITD]: ITDTemplateHelper,
	};

	return new map[templateType]();
};

export const filterRows = (
	rows: IRow[],
	CASData: ICapitalActivityData[],
	templateType: TemplateType,
): IRow[] => {
	const finalRows: IRow[] = [];

	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];

		if (
			!row.hiddenBehavior ||
			!row.hiddenBehavior.includes(EHiddenBehavior.HIDE_WHEN_NULL)
		) {
			finalRows.push(row);
			continue;
		}

		if (
			row.hiddenBehavior &&
			row.hiddenBehavior.includes(EHiddenBehavior.HIDE_WHEN_NULL)
		) {
			if (helperClass(templateType).shouldRenderHiddableRow(row, CASData)) {
				finalRows.push(row);
			}
		}
	}

	return finalRows;
};
