import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const LoginBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '60vh',
}));

export const LoginNotMobile = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100vw',
	height: '60vh',
}));

export const SponsorLogo = styled('img')(() => ({
	maxWidth: '250px',
	maxHeight: '250px',
	height: 'auto',
	paddingBottom: '1rem',
}));
