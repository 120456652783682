import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { DocsState, IAscDesc, ISortStrategy } from './types';
import { IDocumentResponse } from './services/documentApi';
import { DocumentType } from 'app/types/documentTypes.types';
import { Document } from 'app/types/document.types';
import _ from 'lodash';

const initialSortStrategy: ISortStrategy = {
	column: 'createdAt',
	ascdesc: IAscDesc.DESC,
};

const initialState: DocsState = {
	allDocs: [],
	availableDocTypes: [],
	selectedDocTypeIds: [],
	userAllowedDocTypes: [],
	sortStrategy: [initialSortStrategy],
	currentPage: 0,
	currentDocument: undefined,
	fromDocumentsView: false,
	fromEmailNotification: false,
	allTypesSelected: false,
};

export const docsSlice: Slice = createSlice({
	name: 'documentsState',
	initialState,
	reducers: {
		onSetAvailableDocTypes: (state, action: PayloadAction<DocumentType[]>) => {
			state.availableDocTypes = action.payload;
		},
		onSetAllDocs: (state, action: PayloadAction<IDocumentResponse[]>) => {
			state.allDocs = action.payload;
		},
		onSetSelectedDocTypeIds: (state, action: PayloadAction<number[]>) => {
			state.selectedDocTypeIds = action.payload;
			state.allTypesSelected = _.isEqual(
				[...state.selectedDocTypeIds].sort((a, b) => a - b),
				[...state.userAllowedDocTypes.map((d) => d.id)].sort((a, b) => a - b),
			);
		},
		onSetUserAllowedDocTypes: (
			state,
			action: PayloadAction<DocumentType[]>,
		) => {
			state.userAllowedDocTypes = action.payload;
		},
		onSetSortStrategy: (state, action: PayloadAction<ISortStrategy>) => {
			state.sortStrategy = [action.payload];
		},
		onSetCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		onSetCurrentDocument: (state, action: PayloadAction<Document>) => {
			state.currentDocument = action.payload;
		},
		onSetFromDocumentsView: (state, action: PayloadAction<boolean>) => {
			state.fromDocumentsView = action.payload;
		},
		onSetFromEmailNotification: (state, action: PayloadAction<boolean>) => {
			state.fromEmailNotification = action.payload;
		},
	},
});
export const {
	onSetAvailableDocTypes,
	onSetAllDocs,
	onSetSelectedDocTypeIds,
	onSetUserAllowedDocTypes,
	onSetSortStrategy,
	onSetCurrentPage,
	onSetCurrentDocument,
	onSetFromDocumentsView,
	onSetFromEmailNotification,
} = docsSlice.actions;

export const documentsState = ({ docs }: RootState): DocsState => docs;

export default docsSlice.reducer;
