export const STORAGE_KEYS = {
	SPONSOR: {
		SESSION: 'initialSponsorId',
		COOKIE: 'sponsorId',
	},
	FUND: {
		COOKIE: 'fundId',
	},
	INVESTOR: {
		COOKIE: 'investorId',
	},
	DROPDOWN: {
		COOKIE: 'firstDropdown',
		SESSION: 'firstDropdown',
	},
	SUBDOMAIN: {
		COOKIE: 'subdomain',
	},
	TOKEN: {
		COOKIE: 'token',
	},
	REDIRECT_URL: {
		COOKIE: 'redirectUrl',
	},
} as const;
