import { Typography } from '@mui/material';
import { DocumentTables, IDocumentColumnsParams } from './types';
import { formatDate, getDate } from './utils';
import { ActionButtons } from './action-buttons/ActionButtons';

export const documentColumns = ({
	mobile,
	fundName,
	onDelete,
	onRedirect,
	onDownload,
	onPrint,
	onUrlCopy,
	onNotify,
}: IDocumentColumnsParams) => [
	{
		Header: 'Fund',
		id: 'fund',
		accessor: 'fund',
		width: 70,
		Cell: () => <>{fundName}</>,
		isVisible: !mobile,
	},
	{
		Header: 'Investor',
		id: 'investor',
		accessor: 'investor',
		width: 130,
		Cell: ({ row }) => {
			const possibleValues = {
				[DocumentTables.FUND_DOCUMENTS]: <Typography>-</Typography>,
				[DocumentTables.INVESTOR_DOCUMENTS]: row.original.investorName || (
					<Typography>-</Typography>
				),
			};
			return <>{possibleValues[row.original.table]}</>;
		},
		isVisible: !mobile,
	},
	{
		accessor: 'type',
		id: 'type',
		Header: 'Type',
		width: mobile ? 150 : 130,
	},
	{
		Header: 'Document Date',
		accessor: 'period',
		id: 'period',
		width: mobile ? 150 : 90,
		isSorted: true,
		Cell: ({ row }) => {
			const docDate = (period?: string) => {
				if (!period) return '';

				let stringDate = period;

				if (stringDate.length > 10) stringDate = stringDate.substring(0, 10);

				return formatDate(stringDate);
			};

			return <>{formatDate(docDate(row.original.period))}</>;
		},
	},
	{
		Header: 'Date Uploaded',
		accessor: 'createdAt',
		id: 'createdAt',
		width: mobile ? 150 : 90,
		isSorted: true,
		Cell: ({ row }) => <>{formatDate(row.original.createdAt)}</>,
	},
	{
		Header: '',
		accessor: 'actions',
		id: 'actions',
		Cell: ({ row }) => (
			<ActionButtons
				document={row.original}
				onDelete={onDelete}
				onRedirect={onRedirect}
				onDownload={onDownload}
				onPrint={onPrint}
				onUrlCopy={onUrlCopy}
				onNotify={onNotify}
			/>
		),
		isVisible: !mobile,
	},
];
