import { FC, ReactElement } from 'react';
import { THomeProps } from './types';
import { HomeLoader } from './HomeLoader';
import { Login } from './Login';
import { buildBrandedUrl, getRedirectUrl } from './utils';

export const Home: FC = () => {
	const renderHomeContent = (injectedProps: THomeProps): ReactElement => {
		if (injectedProps.isRedirectToBrandedUrl) {
			window.location.href = buildBrandedUrl(
				injectedProps.org,
				injectedProps.subdomain,
				injectedProps.logoUrl,
				injectedProps.baseUrl,
			);

			return <></>;
		}

		if (injectedProps.isShowLoading) {
			return <div>Loading...</div>;
		}

		return (
			<Login
				orgName={injectedProps.orgName}
				logo={injectedProps.logo}
				subdomain={injectedProps.subdomainParam}
				redirectUrl={injectedProps.redirectUrl || getRedirectUrl()}
				color={injectedProps.color}
			/>
		);
	};

	return (
		<HomeLoader>
			{renderHomeContent as (props: THomeProps) => ReactElement}
		</HomeLoader>
	);
};

export default Home;
