import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type TokenRefreshState = {
	isRefreshing: boolean;
};

export type QueryArgs = string | FetchArgs;

const refreshState: TokenRefreshState = {
	isRefreshing: false,
};

const prepareHeaders = (headers: Headers): Headers => {
	const token = sessionStorage.getItem(STORAGE_KEYS.TOKEN.COOKIE);
	if (token) headers.set('Authorization', `Bearer ${token}`);

	return headers;
};

const handleTokenRefresh = (): void => {
	sessionStorage.setItem(
		STORAGE_KEYS.REDIRECT_URL.COOKIE,
		window.location.href,
	);
	window.location.href = '/refresh-token';
};

export const rawBaseQuery = fetchBaseQuery({
	baseUrl: '',
	prepareHeaders,
});

export const buildAdjustedUrl = ({ baseUrl, stem, urlEnd }): string => {
	return `${baseUrl}/${stem}/${urlEnd}`;
};

export const createAdjustedArgs = (
	args: QueryArgs,
	adjustedUrl: string,
): FetchArgs => {
	return typeof args === 'string'
		? { url: adjustedUrl }
		: { ...args, url: adjustedUrl };
};

export const handleUnauthorizedError = (errorData: any): void => {
	if (
		errorData?.errorType === 'token_verification_error' &&
		!refreshState.isRefreshing
	) {
		refreshState.isRefreshing = true;
		try {
			handleTokenRefresh();
		} finally {
			refreshState.isRefreshing = false;
		}
	}
};
