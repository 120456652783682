import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const Maintenance: React.FC = () => {
	return (
		<Container
			maxWidth="sm"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
				textAlign: 'center',
				padding: 4,
			}}
		>
			<Box
				sx={{
					padding: 4,
					borderRadius: 2,
					boxShadow: 3,
					border: '1px solid #ddd',
					textAlign: 'center',
				}}
			>
				<img
					src="/lpx_logo.jpg"
					alt="Logo"
					style={{
						maxWidth: '150px',
						marginBottom: '16px',
					}}
				/>
				<Typography variant="body1" sx={{ color: '#666' }}>
					Site is down for routine maintenance and will be available within the
					next hour. Please check back shortly.
				</Typography>
			</Box>
		</Container>
	);
};

export default Maintenance;
