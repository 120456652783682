import { FC } from 'react';
import { PrimaryActionButtonsProps } from '../types';
import { GetApp, VisibilityOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import {
	UserInvestorPermissionOptions,
	UserInvestorPermissionsWrapper,
} from 'common/helpers/permissions/UserInvestorPermissionsWrapper';
import { IconBtn } from 'common/components/IconBtn';

export const PrimaryActionButtons: FC<PrimaryActionButtonsProps> = ({
	onRedirect,
	onDownload,
	document,
}) => (
	<>
		<IconBtn
			customColor={true}
			onClick={(e) => {
				e.stopPropagation();
				onRedirect(document);
			}}
			tooltip="View"
		>
			<VisibilityOutlined sx={{ width: '24px', height: '24px' }} />
		</IconBtn>

		<UserInvestorPermissionsWrapper
			ability={UserInvestorPermissionOptions.canDownload}
		>
			<IconBtn
				customColor={true}
				onClick={(e) => {
					e.stopPropagation();
					onDownload(document);
				}}
				tooltip="Download"
			>
				<GetApp sx={{ width: '24px', height: '24px' }} />
			</IconBtn>
		</UserInvestorPermissionsWrapper>
	</>
);
