import ICapitalActivity, {
	ICapitalActivityData,
} from './capital-activity.types';
import { IInvestorGrant } from '@redux/services/userApi';
import { IFundCapitalActivity } from './fund-capital-activity.types';

export const csvHeaders = [
	{ label: 'Fund', key: 'details.F' },
	{ label: 'Investor', key: 'details.Investor' },
	{ label: 'Capital Commitment', key: 'details.Capital Commitment' },
	{
		label: 'Opening Capital Account (ITD)',
		key: 'details.Opening Capital Account (ITD)',
	},
	{
		label: 'Opening Capital Account (YTD)',
		key: 'details.Opening Capital Account (YTD)',
	},
	{ label: 'Contributions (ITD)', key: 'details.Contributions (ITD)' },
	{ label: 'Contributions (YTD)', key: 'details.Contributions (YTD)' },
	{ label: 'Transfers (ITD)', key: 'details.Transfers (ITD)' },
	{ label: 'Transfers (YTD)', key: 'details.Transfers (YTD)' },
	{
		label: 'Net Operating Income (Expense) (ITD)',
		key: 'details.Net Operating Income (Expense) (ITD)',
	},
	{
		label: 'Net Operating Income (Expense) (YTD)',
		key: 'details.Net Operating Income (Expense) (YTD)',
	},
	{
		label: 'Realized Investment Gain (Loss) (ITD)',
		key: 'details.Realized Investment Gain (Loss) (ITD)',
	},
	{
		label: 'Realized Investment Gain (Loss) (YTD)',
		key: 'details.Realized Investment Gain (Loss) (YTD)',
	},
	{
		label: 'Unrealized Investment Gain (Loss) (ITD)',
		key: 'details.Unrealized Investment Gain (Loss) (ITD)',
	},
	{
		label: 'Unrealized Investment Gain (Loss) (YTD)',
		key: 'details.Unrealized Investment Gain (Loss) (YTD)',
	},
	{ label: 'Syndication Costs (ITD)', key: 'details.Syndication Costs (ITD)' },
	{ label: 'Syndication Costs (YTD)', key: 'details.Syndication Costs (YTD)' },
	{ label: 'Distributions (ITD)', key: 'details.Distributions (ITD)' },
	{ label: 'Distributions (YTD)', key: 'details.Distributions (YTD)' },
	{ label: 'Closing Capital Account', key: 'details.Closing Capital Account' },
	{ label: 'Capital Called', key: 'details.Capital Called' },
	{
		label: 'Contributions Receivable (Prepaid Contributions)',
		key: 'details.Contributions Receivable (Prepaid Contributions)',
	},
	{
		label: 'Recallable Distributions',
		key: 'details.Recallable Distributions',
	},
	{ label: 'Remaining Commitment', key: 'details.Remaining Commitment' },
	{ label: 'Distributions', key: 'details.Distributions' },
];

export const mtdCsvHeaders = [
	{ label: 'Fund', key: 'details.F' },
	{ label: 'Investor', key: 'details.Investor' },
	{
		label: 'Opening Capital Account (ITD)',
		key: 'details.Opening Capital Account (ITD)',
	},
	{
		label: 'Opening Capital Account (YTD)',
		key: 'details.Opening Capital Account (YTD)',
	},
	{
		label: 'Opening Capital Account (MTD)',
		key: 'details.Opening Capital Account (MTD)',
	},
	{ label: 'Contributions (ITD)', key: 'details.Contributions (ITD)' },
	{ label: 'Contributions (YTD)', key: 'details.Contributions (YTD)' },
	{ label: 'Contributions (YTD)', key: 'details.Contributions (MTD)' },
	{ label: 'Transfers (ITD)', key: 'details.Transfers (ITD)' },
	{ label: 'Transfers (YTD)', key: 'details.Transfers (YTD)' },
	{ label: 'Transfers (YTD)', key: 'details.Transfers (MTD)' },
	{
		label: 'Net Operating Income (Expense) (ITD)',
		key: 'details.Net Operating Income (Expense) (ITD)',
	},
	{
		label: 'Net Operating Income (Expense) (YTD)',
		key: 'details.Net Operating Income (Expense) (YTD)',
	},
	{
		label: 'Net Operating Income (Expense) (YTD)',
		key: 'details.Net Operating Income (Expense) MTD)',
	},
	{
		label: 'Realized Investment Gain (Loss) (ITD)',
		key: 'details.Realized Investment Gain (Loss) (ITD)',
	},
	{
		label: 'Realized Investment Gain (Loss) (YTD)',
		key: 'details.Realized Investment Gain (Loss) (YTD)',
	},
	{
		label: 'Realized Investment Gain (Loss) (MTD)',
		key: 'details.Realized Investment Gain (Loss) (MTD)',
	},
	{
		label: 'Unrealized Investment Gain (Loss) (ITD)',
		key: 'details.Unrealized Investment Gain (Loss) (ITD)',
	},
	{
		label: 'Unrealized Investment Gain (Loss) (YTD)',
		key: 'details.Unrealized Investment Gain (Loss) (YTD)',
	},
	{
		label: 'Unrealized Investment Gain (Loss) (MTD)',
		key: 'details.Unrealized Investment Gain (Loss) (MTD)',
	},
	{ label: 'Syndication Costs (ITD)', key: 'details.Syndication Costs (ITD)' },
	{ label: 'Syndication Costs (YTD)', key: 'details.Syndication Costs (YTD)' },
	{ label: 'Syndication Costs (MTD)', key: 'details.Syndication Costs (MTD)' },
	{ label: 'Distributions (ITD)', key: 'details.Distributions (ITD)' },
	{ label: 'Distributions (YTD)', key: 'details.Distributions (YTD)' },
	{ label: 'Distributions (MTD)', key: 'details.Distributions (MTD)' },
	{ label: 'Closing Capital Account', key: 'details.Closing Capital Account' },
	{ label: 'Rate of Return (ITD)', key: 'details.Rate of Return (ITD)' },
	{ label: 'Rate of Return (YTD)', key: 'details.Rate of Return (YTD)' },
	{ label: 'Rate of Return (MTD)', key: 'details.Rate of Return (MTD)' },
	{ label: 'Distributions', key: 'details.Distributions' },
];

export const csvExport = (
	capitalActivity: ICapitalActivity | undefined,
	fundCapitalActivityData: IFundCapitalActivity | undefined,
	currentInvestor: IInvestorGrant,
): Record<string, unknown>[] => {
	if (!capitalActivity || !currentInvestor || !fundCapitalActivityData) {
		return [];
	}

	const investorDetails = transformAttributesToObject(
		capitalActivity.attributeData,
	);

	const fundDetails = transformAttributesToObject(
		[
			...fundCapitalActivityData.attributeData,
			{ name: 'F', value: 'Fund Level' },
		].filter((attr) => attr.name !== 'Investor'),
	);

	return [{ details: investorDetails }, { details: fundDetails }];
};

export const mtdCsvExport = (
	capitalActivity: ICapitalActivity | undefined,
	currentInvestor: IInvestorGrant,
): Record<string, unknown>[] => {
	if (!capitalActivity || !currentInvestor) {
		return [];
	}

	const investorDetails = transformAttributesToObject(
		capitalActivity.attributeData,
	);

	return [{ details: investorDetails }];
};

const transformAttributesToObject = (
	attributes: ICapitalActivityData[],
): Record<string, string> => {
	return attributes.reduce<Record<string, string>>((acc, attr) => {
		acc[attr.name] = attr.value;
		return acc;
	}, {});
};
