import { Box } from '@mui/material';
import { IActionButtonsProps } from '../types';
import { PrimaryActionButtons } from './PrimaryActionButtons';
import { DocumentActionsMenu } from './DocumentActionsMenu';

export const ActionButtons = ({
	document,
	onDelete,
	onRedirect,
	onDownload,
	onPrint,
	onUrlCopy,
	onNotify,
}: IActionButtonsProps) => (
	<Box style={{ padding: '5px', textAlign: 'end' }}>
		<PrimaryActionButtons
			onRedirect={onRedirect}
			onDownload={onDownload}
			document={document}
		/>

		<DocumentActionsMenu
			document={document}
			onPrint={onPrint}
			onUrlCopy={onUrlCopy}
			onDelete={onDelete}
			onNotify={onNotify}
			documentType={document.type}
		/>
	</Box>
);
