import { EColumnTitle, ICapitalActivityTemplate, IColumn } from './types';

export const getInvestorColumns = (
	template: ICapitalActivityTemplate,
): IColumn[] => {
	return template.columns.filter((column) => column.title[0].text);
};

export const getFundColumns = (
	template: ICapitalActivityTemplate,
): IColumn[] => {
	return template.columns.filter(
		(column) =>
			column.title[0].text && column.title[0].text === EColumnTitle.FUND_LEVEL,
	);
};

export const getReportingPeriod = (reportingPeriod: string): string => {
	if (!reportingPeriod) {
		return '';
	}

	const endDate = reportingPeriod.replace('Period ending ', '');
	const year = new Date(endDate).getFullYear();

	return `January 1, ${year}`;
};
