import { Investor } from 'app/types/investor.types';

export const csvHeaders = [
	{ label: 'Fund', key: 'details.F' },
	{ label: 'Investor', key: 'details.ID' },
	{ label: 'Investor ID', key: 'details.II' },
	{ label: 'Capital Commitment', key: 'details.CC' },
	{ label: 'Opening Capital Account (ITD)', key: 'details.OCAITD' },
	{ label: 'Opening Capital Account (YTD)', key: 'details.OCAYTD' },
	{ label: 'Contributions (ITD)', key: 'details.CITD' },
	{ label: 'Contributions (YTD)', key: 'details.CYTD' },
	{ label: 'Transfers (ITD)', key: 'details.TITD' },
	{ label: 'Transfers (YTD)', key: 'details.TYTD' },
	{ label: 'Net Operating Income (Expense) (ITD)', key: 'details.NOIITD' },
	{ label: 'Net Operating Income (Expense) (YTD)', key: 'details.NOIYTD' },
	{ label: 'Realized Investment Gain (Loss) (ITD)', key: 'details.RIGITD' },
	{ label: 'Realized Investment Gain (Loss) (YTD)', key: 'details.RIGYTD' },
	{ label: 'Unrealized Investment Gain (Loss) (ITD)', key: 'details.UIGITD' },
	{ label: 'Unrealized Investment Gain (Loss) (YTD)', key: 'details.UIGYTD' },
	{ label: 'Syndication Costs (ITD)', key: 'details.SCITD' },
	{ label: 'Syndication Costs (YTD)', key: 'details.SCYTD' },
	{ label: 'Distributions (ITD)', key: 'details.DITD' },
	{ label: 'Distributions (YTD)', key: 'details.DYTD' },
	{ label: 'Closing Capital Account', key: 'details.CCA' },
	{ label: 'Capital Called', key: 'details.TCO' },
	{
		label: 'Contributions Receivable (Prepaid Contributions)',
		key: 'details.COR',
	},
	{ label: 'Recallable Distributions', key: 'details.RDIS' },
	{ label: 'Remaining Commitment', key: 'details.RC' },
	{ label: 'Distributions', key: 'details.DIS' },
];

export const mtdCsvHeaders = [
	{ label: 'Fund', key: 'details.F' },
	{ label: 'Investor', key: 'details.ID' },
	{ label: 'Investor ID', key: 'details.II' },
	{ label: 'Opening Capital Account (ITD)', key: 'details.OCAITD' },
	{ label: 'Opening Capital Account (YTD)', key: 'details.OCAYTD' },
	{ label: 'Opening Capital Account (MTD)', key: 'details.OCAMTD' },
	{ label: 'Contributions (ITD)', key: 'details.CITD' },
	{ label: 'Contributions (YTD)', key: 'details.CYTD' },
	{ label: 'Contributions (MTD)', key: 'details.CMTD' },
	{ label: 'Transfers (ITD)', key: 'details.TITD' },
	{ label: 'Transfers (YTD)', key: 'details.TYTD' },
	{ label: 'Transfers (MTD)', key: 'details.TMTD' },
	{ label: 'Net Operating Income (Expense) (ITD)', key: 'details.NOIITD' },
	{ label: 'Net Operating Income (Expense) (YTD)', key: 'details.NOIYTD' },
	{ label: 'Net Operating Income (Expense) (MTD)', key: 'details.NOIMTD' },
	{ label: 'Realized Investment Gain (Loss) (ITD)', key: 'details.RIGITD' },
	{ label: 'Realized Investment Gain (Loss) (YTD)', key: 'details.RIGYTD' },
	{ label: 'Realized Investment Gain (Loss) (MTD)', key: 'details.RIGMTD' },
	{ label: 'Unrealized Investment Gain (Loss) (ITD)', key: 'details.UIGITD' },
	{ label: 'Unrealized Investment Gain (Loss) (YTD)', key: 'details.UIGYTD' },
	{ label: 'Unrealized Investment Gain (Loss) (MTD)', key: 'details.UIGMTD' },
	{ label: 'Syndication Costs (ITD)', key: 'details.SCITD' },
	{ label: 'Syndication Costs (YTD)', key: 'details.SCYTD' },
	{ label: 'Syndication Costs (MTD)', key: 'details.SCMTD' },
	{ label: 'Distributions (ITD)', key: 'details.DITD' },
	{ label: 'Distributions (YTD)', key: 'details.DYTD' },
	{ label: 'Distributions (MTD)', key: 'details.DMTD' },
	{ label: 'Closing Capital Account', key: 'details.CCA' },
	{ label: 'Rate of Return (ITD)', key: 'details.RORITD' },
	{ label: 'Rate of Return (YTD)', key: 'details.RORYTD' },
	{ label: 'Rate of Return (MTD)', key: 'details.RORMTD' },
	{ label: 'Distributions', key: 'details.DIS' },
];

const fundRow = {
	details: {
		F: 'Fund Level',
		ID: '',
		II: '',
		CC: '',
		OCAITD: '',
		OCAYTD: '',
		CITD: '',
		CYTD: '',
		NOIITD: '',
		NOIYTD: '',
		RIGITD: '',
		RIGYTD: '',
		UIGITD: '',
		UIGYTD: '',
		SCITD: '',
		SCYTD: '',
		TITD: '',
		TYTD: '',
		DITD: '',
		DYTD: '',
		CCA: '',
		TCO: '',
		COR: '',
		RDIS: '',
		RC: '',
		DIS: '',
	},
};

const investorRow = (investor: Investor) => ({
	details: {
		F: '',
		ID: investor.name,
		II: investor.investorId,
		CC: '',
		OCAITD: '',
		OCAYTD: '',
		CITD: '',
		CYTD: '',
		NOIITD: '',
		NOIYTD: '',
		RIGITD: '',
		RIGYTD: '',
		UIGITD: '',
		UIGYTD: '',
		SCITD: '',
		SCYTD: '',
		TITD: '',
		TYTD: '',
		DITD: '',
		DYTD: '',
		CCA: '',
		TCO: '',
		COR: '',
		RDIS: '',
		RC: '',
		DIS: '',
	},
});

const mtdInvestorRow = (investor: Investor) => ({
	details: {
		F: '',
		ID: investor.name,
		II: investor.investorId,
		OCAITD: '',
		OCAYTD: '',
		OCAMTD: '',
		CITD: '',
		CYTD: '',
		CMTD: '',
		TITD: '',
		TYTD: '',
		TMTD: '',
		NOIITD: '',
		NOIYTD: '',
		NOIMTD: '',
		RIGITD: '',
		RIGYTD: '',
		RIGMTD: '',
		UIGITD: '',
		UIGYTD: '',
		UIGMTD: '',
		SCITD: '',
		SCYTD: '',
		SCMTD: '',
		DITD: '',
		DYTD: '',
		DMTD: '',
		CCA: '',
		RORITD: '',
		RORYTD: '',
		RORMTD: '',
		DIS: '',
	},
});

export const csvData = (investors: Investor[]) => {
	const data: any[] = [];

	for (let i = 0; i < investors.length; i++) {
		const investor = investors[i];

		data.push(investorRow(investor));
	}

	data.push(fundRow);

	return data;
};

export const mtdCsvData = (investors: Investor[]) => {
	const data: any[] = [];

	for (let i = 0; i < investors.length; i++) {
		const investor = investors[i];

		data.push(mtdInvestorRow(investor));
	}
	return data;
};

export enum TemplateType {
	ITD = 'YTD & ITD w/ Fund Level',
	NO_ITD = 'YTD w/ Fund Level',
	YTD_ITD = 'YTD & ITD',
	YTD = 'YTD',
	MTD = 'MTD, YTD, ITD',
}
