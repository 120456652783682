import { Box, FormControlLabel, Paper, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect } from 'react';
import _ from 'lodash';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	documentsState,
	onSetSelectedDocTypeIds,
} from 'api/redux/DocumentsReducer';
import { selectNavbarMobile } from 'api/redux/NavBarStateReducer';
import { styled } from '@mui/system';

const FilterContainerPaper = styled(Paper)(() => ({
	padding: 5,
	height: '100%',
	overflow: 'auto',
	borderRadius: '0px',
	borderLeft: '1px solid #D0D7DE',
}));

export const DocumentFilters: FC = () => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const docs = useSelector(documentsState);
	const { userAllowedDocTypes, selectedDocTypeIds } = docs;
	const dispatch = useDispatch();
	const isMobile = useSelector(selectNavbarMobile);
	const allTypesSelected = _.isEqual(
		[...selectedDocTypeIds].sort((a, b) => a - b),
		[...userAllowedDocTypes.map((d) => d.id)].sort((a, b) => a - b),
	);

	const handleDocTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (allTypesSelected) {
			dispatch(onSetSelectedDocTypeIds([Number(e.target.value)]));
			return;
		}

		const selectedIds = [...selectedDocTypeIds];
		if (e.target.checked) {
			dispatch(
				onSetSelectedDocTypeIds([...selectedIds, Number(e.target.value)]),
			);
			return;
		}

		const index = selectedIds.indexOf(Number(e.target.value));
		selectedIds.splice(index, 1);
		dispatch(onSetSelectedDocTypeIds(selectedIds));
	};

	const handleAllDocumentsClick = () => {
		if (!allTypesSelected) {
			dispatch(onSetSelectedDocTypeIds(userAllowedDocTypes.map((d) => d.id)));
			return;
		}
		dispatch(onSetSelectedDocTypeIds([]));
	};

	useEffect(() => {
		if (allTypesSelected && userAllowedDocTypes.length > 0) {
			const allTypeIds = userAllowedDocTypes.map((type) => type.id);
			dispatch(onSetSelectedDocTypeIds(allTypeIds));
		}
	}, [userAllowedDocTypes, allTypesSelected, dispatch]);

	const DocumentTypeOptions: FC = () => {
		const docs = useSelector(documentsState);
		const { userAllowedDocTypes, selectedDocTypeIds } = docs;
		const allTypesSelected = _.isEqual(
			[...selectedDocTypeIds].sort((a, b) => a - b),
			[...userAllowedDocTypes.map((d) => d.id)].sort((a, b) => a - b),
		);

		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
				{userAllowedDocTypes.map((docType) => (
					<FormControlLabel
						key={docType.id}
						id={`fc-doc-type-${docType.id}`}
						label={docType.name}
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								size="small"
								id={`cb-doc-type-${docType.id}`}
								value={docType.id}
								checked={selectedDocTypeIds.includes(docType.id)}
								onChange={handleDocTypeChange}
								sx={{
									color: sponsorColor,
									'&.Mui-checked': {
										color: allTypesSelected ? '#c0c0c0' : sponsorColor,
									},
									padding: '0px 5px 0px 0px',
									'.MuiSvgIcon-fontSizeSmall': {
										height: '18px',
										width: '18px',
									},
								}}
							/>
						}
					/>
				))}
			</Box>
		);
	};

	const AllOption: FC = () => {
		const docs = useSelector(documentsState);
		const { userAllowedDocTypes, selectedDocTypeIds, allDocs } = docs;
		const sortedSelectedDocTypeIds = [...selectedDocTypeIds].sort(
			(a, b) => a - b,
		);
		const sortedAvailableDocTypeIds = [
			...userAllowedDocTypes.map((dt) => dt.id),
		].sort((a, b) => a - b);
		const allSelected: boolean = _.isEqual(
			sortedSelectedDocTypeIds,
			sortedAvailableDocTypeIds,
		);

		return (
			<FormControlLabel
				label="All Documents"
				control={
					<Checkbox
						checked={allSelected}
						onChange={handleAllDocumentsClick}
						size="small"
						sx={{
							color: sponsorColor,
							'&.Mui-checked': {
								color: sponsorColor,
							},
							padding: '0px 5px 0px 10px',
							'.MuiSvgIcon-fontSizeSmall': {
								height: '18px',
								width: '18px',
							},
						}}
					/>
				}
			/>
		);
	};

	const DocumentTypesFilter: FC = () => {
		return (
			<Box>
				<AllOption />
				<DocumentTypeOptions />
			</Box>
		);
	};

	if (isMobile) return <></>;

	return (
		<Box sx={{ height: '100%', maxHeight: '100%' }}>
			<FilterContainerPaper elevation={0}>
				<Typography>Filters</Typography>
				<DocumentTypesFilter />
			</FilterContainerPaper>
		</Box>
	);
};
