import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { getCookie } from 'common/helpers/Cookies';
import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import Home from './Home';
import { ERoutes } from 'App';

/**
 * A component served at the /logout route that handles user logout functionality.
 *
 * This component cleans the sessionStorage and uses the Auth0 API to log out the user.
 * After logout, Auth0 redirects to the current location, but since the Auth0 session
 * is terminated, the authWrapper logic will redirect the user to the login page.
 *
 * This route can be used as a troubleshooting mechanism for users experiencing
 * authentication issues.
 *
 * @route /logout
 */
export const Logout: FC = () => {
	const { logout, user } = useAuth0();

	const [, , removeCookies] = useCookies([
		STORAGE_KEYS.FUND.COOKIE,
		STORAGE_KEYS.SPONSOR.COOKIE,
		STORAGE_KEYS.INVESTOR.COOKIE,
		STORAGE_KEYS.SUBDOMAIN.COOKIE,
	]);

	const handleLogout = () => {
		if (user) {
			const url = new URL(window.location.origin);
			const subdomain = getCookie(STORAGE_KEYS.SUBDOMAIN.COOKIE);
			if (subdomain) {
				url.searchParams.append(STORAGE_KEYS.SUBDOMAIN.COOKIE, subdomain);
			}

			logout({ logoutParams: { returnTo: url.toString() } });
			removeCookies(STORAGE_KEYS.FUND.COOKIE, { path: ERoutes.Dashboard });
			removeCookies(STORAGE_KEYS.SPONSOR.COOKIE, { path: ERoutes.Dashboard });
			removeCookies(STORAGE_KEYS.INVESTOR.COOKIE, { path: ERoutes.Dashboard });
			removeCookies(STORAGE_KEYS.SUBDOMAIN.COOKIE, { path: ERoutes.Dashboard });
		}
	};

	handleLogout();

	return <Home />;
};

export default Logout;
