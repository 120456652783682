import { FC, useCallback, useEffect, useState } from 'react';
import { useGetOrganizationsQuery } from 'api/redux/services/organizations.service';
import AdminSidebar from 'modules/admin/AdminSideBar';
import { Box } from '@mui/material';
import { ManageInvestors } from './ManageInvestors';
import { UploadData } from 'modules/admin/UploadData';
import AdminBreadcrumbs from 'common/components/AdminBreadCrumbs';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { useGetFundsQuery } from 'api/redux/services/fundApi';
import { UploadDocuments } from 'modules/admin/UploadDocuments/UploadDocuments';
import {
	onChangeFund,
	onChangeUserGrants,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { onSetSuccessMessage } from 'api/redux/DocsUploadReducer';
import {
	ContentSection,
	RootContainer,
	SideBarContainer,
} from 'modules/styles';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';

enum FundMenuOptions {
	Investors = 'Edit Investors',
	Documents = 'Upload Documents',
	UploadData = 'Upload Data',
}

const hideProtectedMenuOption = (
	optionName: FundMenuOptions,
	scopes: SCOPES[],
	options: string[],
): void => {
	if (!hasPermission({ scopes })) {
		const bulkUploadOptionIndex = options.indexOf(optionName);
		options.splice(bulkUploadOptionIndex, 1);
	}
};

export const FundPage: FC = () => {
	const [currentTab, setCurrentTab] = useState('Investors');
	const { data: organizations = [] } = useGetOrganizationsQuery({});
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor, currentFund, availableSponsors } = grants;
	const thisOrganization = organizations.filter(
		(f) => f.id === currentSponsor.id,
	)[0];

	const { data: funds = [] } = useGetFundsQuery(currentSponsor.id);

	const thisFund = funds.filter((f) => f.id === currentFund.id)[0];

	const dispatch = useDispatch();

	const renderTabComponent = useCallback(() => {
		dispatch(onSetSuccessMessage(false));
		switch (currentTab) {
			case FundMenuOptions.Investors:
				return <ManageInvestors />;
			case FundMenuOptions.Documents:
				return <UploadDocuments />;
			case FundMenuOptions.UploadData:
				return <UploadData />;
			default:
				return <ManageInvestors />;
		}
	}, [currentTab]);

	const menuOptions = Object.values(FundMenuOptions);

	hideProtectedMenuOption(
		FundMenuOptions.Documents,
		[SCOPES.canSeeBulkUploadOption],
		menuOptions,
	);

	hideProtectedMenuOption(
		FundMenuOptions.UploadData,
		[SCOPES.canSeeUploadDataOption],
		menuOptions,
	);

	useEffect(() => {
		return () => {
			dispatch(
				onChangeUserGrants({
					userGrants: availableSponsors,
					loadCookies: true,
				}),
			);
		};
	}, []);

	return (
		<MainSidebarContainer>
			<RootContainer maxWidth={false}>
				<SideBarContainer maxWidth={false}>
					<AdminSidebar
						onClick={setCurrentTab}
						options={Object.values(FundMenuOptions)}
						title="Fund Management"
					/>
				</SideBarContainer>
				<ContentSection maxWidth={false}>
					<AdminBreadcrumbs
						list={[
							{ text: 'Admin', link: '/admin' },
							{
								text: thisOrganization?.name,
								link: `/superadmin/sponsors`,
							},
							{ text: thisFund?.name },
						]}
					/>
					<Box>{renderTabComponent()}</Box>
				</ContentSection>
			</RootContainer>
		</MainSidebarContainer>
	);
};
