/**
 * This file is retained here temporarily solely for maintaining backward compatibility.
 *
 * It will be removed once rendering CAS prior to Feb-2024 is deemed unnecessary.
 * Note the resemblance of many declarations in this file to CASTable/types.ts.
 * The latter is designated to persist.
 */

import { ReportType } from 'app/types/reportTypes.types';
import ICapitalActivity from './capital-activity.types';
import { IFundCapitalActivity } from './fund-capital-activity.types';
import { Dispatch, SetStateAction } from 'react';
import { IFund } from 'app/types/fund.types';
export interface ICapitalActivityTemplate {
	columns: IColumn[];
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	active: boolean;
	footer: string[];
	templateId: string;
	id: number;
	rows: IRow[];
	title: ITitle[];
}

export const TemplateTypesToColSpan = {
	'PE - Inception': 2,
	'PE - Inception with T&S': 2,
	'PE - No Inception': 1,
	'PE - No Inception with T&S': 1,
	'PE - Inception with Contributions Receivable': 2,
	'PE - No Inception with Contributions Receivable': 1,
};

export interface ICapitalAccountStatementProps {
	period?: string;
}

export interface ICapitalAccountStatementTableProps {
	data?: ICapitalActivity;
	fundData: IFundCapitalActivity;
}

export interface ICapitalAccountStatementHeader {
	currentReportingPeriod: string;
	setError: Dispatch<SetStateAction<string | undefined>>;
	capitalActivityData: ICapitalActivity[];
	fundCapitalActivityData: IFundCapitalActivity | undefined;
	afterDelete: () => void;
	loadCapitalActivity: (
		e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => void;
	investorAllowedReportTypes: ReportType[];
	hasPermission: boolean;
}

export enum INullBehavior {
	DASH = 'DASH',
	BLANK = 'BLANK',
	ZERO = 'ZERO',
	DOLLAR_SIGN_AND_DASH = 'DOLLAR SIGN AND DASH',
}

export enum CellValueFormats {
	UNDERLINE = 'UNDERLINE',
	NUMBER = 'NUMBER',
	CURRENCY = 'CURRENCY',
	DOUBLE_UNDERLINE = 'DOUBLE_UNDERLINE',
	TOP_LINE = 'TOP_LINE',
	THICK_UNDERLINE = 'THICK_UNDERLINE',
	PERCENTAGE = 'PERCENTAGE',
}

export interface IRow {
	label: ILabel;
	nullBehavior: INullBehavior;
	valueFormats: CellValueFormats;
}

export enum ETimePeriodSuffixes {
	ITD = 'ITD',
	YTD = 'YTD',
	PTD = 'PTD',
	MTD = 'MTD',
	NONE = 'None',
}

export enum EColumnTitle {
	FUND_LEVEL = 'Fund Level',
	INVESTOR_LEVEL = 'Investor Level',
	PERIOD_ENDED = 'For the period ended {{REPORTING_PERIOD}}',
}
export interface IColumnTitle {
	text: EColumnTitle;
	formats: CellValueFormats[];
}
export interface IColumn {
	timePeriodSuffix: ETimePeriodSuffixes;
	title: IColumnTitle[];
}

export interface ILabel {
	formats: Array<'BOLD' | 'INDENT'>;
	text: string;
}

export interface ITitle {
	text: string;
	formats: string[];
}

export interface ICapitalActivityTableProps {
	visible: boolean;
	fundCapitalActivityData: IFundCapitalActivity | undefined;
	capitalActivityData: ICapitalActivity | undefined;
	// foundCapitalActivity: ICapitalActivity | undefined;
}

export interface IMessageParagraphProps {
	message: string;
}

export interface IHeaderOptionsProps {
	loadCapitalActivity: (
		e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => void;
	currentReportingPeriod: string;
	setError: Dispatch<SetStateAction<string | undefined>>;
	capitalActivityData: ICapitalActivity[];
	fundCapitalActivityData: IFundCapitalActivity | undefined;
	afterDelete: () => void;
	hasPermission: boolean;
}

export interface IEdgeCaseMessageProps {
	investorAllowedReportTypes: ReportType[];
	capitalActivityData: ICapitalActivity[];
}

export const EMPTY_REPORTS_COLLECTION =
	'You do not have any capital activity data yet.';
export const REPORT_TYPE_NOT_ALLOWED = (reportTypeName: string): string =>
	`You have not been granted access to this ${reportTypeName}`;

export interface IPrintCasButtonProps {
	onClick?: () => void;
	visible?: boolean;
}

export interface IPeriodSelector {
	currentReportingPeriod: string;
	capitalActivityData: ICapitalActivity[];
	loadCapitalActivity: (
		e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => void;
	visible: boolean;
}

export enum TemplateType {
	ITD = 'YTD & ITD w/ Fund Level',
	NO_ITD = 'YTD w/ Fund Level',
	YTD_ITD = 'YTD & ITD',
	YTD = 'YTD',
	MTD = 'MTD, YTD, ITD',
}

export interface IGetTemplateIdParams {
	templates: ICapitalActivityTemplate[] | undefined;
	fund: IFund | undefined;
}

export interface ICSVReportConfig {
	data: Record<string, unknown>[];
	headers: { label: string; key: string }[];
	filename: string;
}
