import { FC, useState } from 'react';
import {
	DocumentActionsMenuProps,
	DocumentNotificationMenuItemProps,
	alloweDocumentTypesToManualNotifications,
} from '../types';
import { IconBtn } from 'common/components/IconBtn';
import {
	ContentCopy,
	Delete,
	MoreVert,
	Notifications,
	Print,
} from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';

export const DocumentActionsMenu: FC<DocumentActionsMenuProps> = ({
	document,
	onDelete,
	onPrint,
	onUrlCopy,
	onNotify,
	documentType,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuAction = (
		action: (doc: typeof document) => void,
		event: React.MouseEvent,
	) => {
		event.stopPropagation();
		action(document);
		handleClose();
	};

	return (
		<>
			<IconBtn customColor={true} onClick={handleClick} tooltip="More actions">
				<MoreVert sx={{ width: '24px', height: '24px' }} />
			</IconBtn>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={(e) => e.stopPropagation()}
			>
				<MenuItem onClick={(e) => handleMenuAction(onPrint, e)}>
					<Print sx={{ mr: 1 }} /> Print
				</MenuItem>

				<MenuItem onClick={(e) => handleMenuAction(onUrlCopy, e)}>
					<ContentCopy sx={{ mr: 1 }} /> Copy Url
				</MenuItem>

				<PermissionsWrapper scopes={[SCOPES.canNotifyOnDocuments]}>
					<DocumentNotificationMenuItem
						documentType={documentType}
						onClick={(e) => handleMenuAction(onNotify, e)}
					/>
				</PermissionsWrapper>

				<PermissionsWrapper scopes={[SCOPES.canDeleteDocument]}>
					<MenuItem onClick={(e) => handleMenuAction(onDelete, e)}>
						<Delete sx={{ mr: 1 }} /> Delete
					</MenuItem>
				</PermissionsWrapper>
			</Menu>
		</>
	);
};

const DocumentNotificationMenuItem = ({
	documentType,
	onClick,
}: DocumentNotificationMenuItemProps) => {
	if (
		!documentType ||
		!alloweDocumentTypesToManualNotifications.includes(documentType)
	) {
		return null;
	}

	return (
		<MenuItem onClick={onClick}>
			<Notifications sx={{ mr: 1 }} /> Notify
		</MenuItem>
	);
};
