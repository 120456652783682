import { createApi } from '@reduxjs/toolkit/query/react';
import dynamicBaseQuery from 'app/dynamic-base-query';

export interface IFileDownloadRequest {
	id: number;
	status: EFileDownloadStatus;
	gcloudPath: string;
}

export enum EFileDownloadStatus {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

export const fileDownloadApi = createApi({
	reducerPath: 'fileDownloadApi',
	baseQuery: dynamicBaseQuery('file-downloads'),
	endpoints: (builder) => ({
		getFileDownloadRequest: builder.query<IFileDownloadRequest, number>({
			query: (id) => ({
				url: `${id}`,
			}),
		}),
	}),
});

export const { useLazyGetFileDownloadRequestQuery } = fileDownloadApi;
