import { GetApp } from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import { styled } from '@mui/system';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useBulkDownloadMutation } from 'api/redux/services/documentApi';
import { documentsState } from 'api/redux/DocumentsReducer';
import { IconBtn } from 'common/components/IconBtn';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	EFileDownloadStatus,
	useLazyGetFileDownloadRequestQuery,
} from 'api/redux/services/fileDownloadApi';

const IconWrapper = styled(Box)({
	position: 'absolute',
	right: '4.4rem',
	zIndex: 2,
});

export const BulkDownloadButton: FC = () => {
	const { selectedDocTypeIds } = useSelector(documentsState);
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor, currentFund, currentInvestor } = grants;
	const [
		bulkDownloadRequest,
		{ isSuccess: isRequestSuccess, data: initialFileDownloadRequest },
	] = useBulkDownloadMutation();
	const [getFileDownloadRequest, { data: fileDownloadRequest, isFetching }] =
		useLazyGetFileDownloadRequestQuery();
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isDownloadPending, setIsDownloadPending] = useState(false);

	const handleBulkDownload = () => {
		bulkDownloadRequest({
			sponsorId: currentSponsor.id,
			fundId: currentFund.id,
			investorId: currentInvestor.id,
			documentTypeIds: selectedDocTypeIds,
		});
		setIsLoading(true);
	};

	useEffect(() => {
		if (!isDownloadPending || !initialFileDownloadRequest) return;

		getFileDownloadRequest(initialFileDownloadRequest.id);
	}, [isDownloadPending, initialFileDownloadRequest]);

	useEffect(() => {
		if (!isRequestSuccess) return;

		setIsDownloadPending(true);
	}, [isRequestSuccess]);

	useEffect(() => {
		if (!fileDownloadRequest || isFetching) return;

		if (fileDownloadRequest.status === EFileDownloadStatus.COMPLETED) {
			const link = document.createElement('a');
			link.href = fileDownloadRequest.gcloudPath;
			link.download = 'documents.zip';
			document.body.appendChild(link);

			link.click();
			setIsLoading(false);
			setIsDownloadPending(false);
			return;
		}

		if (fileDownloadRequest.status === EFileDownloadStatus.FAILED) {
			setIsError(true);
			console.error('Failed to download documents');
			return;
		}

		if (fileDownloadRequest.status === EFileDownloadStatus.PENDING) {
			setTimeout(() => {
				getFileDownloadRequest(fileDownloadRequest.id);
			}, 3000);
		}
	}, [fileDownloadRequest, isFetching]);

	if (!selectedDocTypeIds.length) return null;

	return (
		<>
			{isError && (
				<Alert severity="error">An error occurred on download documents </Alert>
			)}
			{isDownloadPending && (
				<Alert severity="success">Download in progress...</Alert>
			)}
			<IconWrapper>
				<IconBtn
					tooltip="Download filtered documents"
					onClick={handleBulkDownload}
					customColor={true}
					disabled={isLoading || isError}
				>
					{isLoading ? (
						<LoadingSpinner />
					) : (
						<GetApp sx={{ width: '24px', height: '24px' }} />
					)}
				</IconBtn>
			</IconWrapper>
		</>
	);
};
