import { FC } from 'react';
import { ICASTableBodyProps, TemplateType, IRow } from './types';
import { getFundColumns, getInvestorColumns } from './utils';
import { ITDCASTableRow } from './ITDCASTableRow';
import { TableBody } from '@mui/material';
import { filterRows } from './filterRows';

export const ITDCASTableBody: FC<ICASTableBodyProps> = ({
	capitalActivityTemplate,
	CASData,
}) => {
	const { rows } = capitalActivityTemplate;
	const columns = [
		...getInvestorColumns(capitalActivityTemplate),
		...getFundColumns(capitalActivityTemplate),
	];

	const filteredRows = filterRows(
		rows,
		CASData,
		capitalActivityTemplate.templateId as TemplateType,
	);

	return (
		<TableBody style={{ width: '100%' }}>
			{filteredRows.map((row: IRow, i) => {
				return (
					<ITDCASTableRow
						key={i}
						row={row}
						columns={columns}
						CASData={CASData}
						templateType={capitalActivityTemplate.templateId as TemplateType}
					/>
				);
			})}
		</TableBody>
	);
};
